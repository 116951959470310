import React from 'react'
import './BlogPage.css'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export default function BlogPage() {
  const useStyles = makeStyles({
    root: {
      maxWidth: 500
    },
  });
  const classes = useStyles();

  return (
    <section className="blog-container">
      <div className="blog-header-container">
        <h1 className="blog-header">BLOG</h1>
      </div>
      <div className="blogs">
      <Card className={classes.root}>
          <CardActionArea>
          <CardMedia
            component="img"
            alt="AWS Hackathon Winning Team"
            height="140"
            image="https://i.imgur.com/b0iy06u.png"
            title="Becoming a Dev: My First Hackathon"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Becoming a Dev: My First Hackathon
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              My Experience Participating in the AWS Portland Hackathon.
            </Typography>
          </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://github.com/team-jhemm-fighters/jhemm-fighters">
              GitHub
            </Button>
          </CardActions>
        </Card>
      </div>
    </section>
  )
}
