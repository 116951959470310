import React from 'react'
import './HomePage.css'

export default function HomePage() {
  return (
      <section className="home_container">
        <div className="title_text_container">
          <h1 className="title">Joey Leaptrott</h1>
          <h2 className="subtitle">Fullstack Software Developer</h2>
        </div>
        <div className="wave wave1"></div>
        <div className="wave wave2"></div>
      </section>
  )
}
