import React, { useState } from 'react'
import './ContactPage.css'

export default function BlogPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const handleChange = ({target}) => {
    if(target.name === 'name') setName(target.value)
    if(target.name === 'email') setEmail(target.value)
    if(target.name === 'message') setMessage(target.value)
  }

  const handleSubmit = (e) => { 
    e.preventDefault();
    const emailObject = {name, email, message}
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", emailObject})
      })
        .then(() => {
          alert("Success!")
          setName('')
          setEmail('')
          setMessage('')
        })
        .catch(error => alert(error));
  }

  return (
    <section className="contact-container">
      <div className="contact-header-container">
        <h1 className="contact-header">CONTACT</h1>
      </div>
      <div className="contact-form-container">
        <form onSubmit={handleSubmit} name="contact" method="post">
            <input name="name" type="text" className="name-input" value={name} onChange={handleChange} placeholder="Name"/>
            <input name="email" type="text" className="email-input" value={email} onChange={handleChange} placeholder="Email"/>
            <textarea name="message" className="message-input" value={message} onChange={handleChange} placeholder="Message"/>
            <button type="submit">Send</button>
        </form>
      </div>
    </section>
  )
}
