import React from 'react'
import './ProjectPage.css'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 500
  },
});

export default function ProjectPage() {
  const classes = useStyles();

  return (
    <section className="project-container">
      <div className="project-header-container">
        <h1 className="project-header">PROJECTS</h1>
      </div>
      <div className="projects">
        <Card className={classes.root}>
          <CardActionArea>
          <CardMedia
            component="img"
            alt="Jhemm Fighters Splash Page"
            height="140"
            image="https://i.imgur.com/7oaQEQz.png"
            title="Jhemm Fighters"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Jhemm Fighters
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Jhemm Fighters is a JavaScript application that allows players to battle against each other.
              Developed during a weeklong sprint.
            </Typography>
          </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://github.com/team-jhemm-fighters/jhemm-fighters">
              GitHub
            </Button>
            <Button size="small" color="primary" href="https://team-jhemm-fighters.github.io/jhemm-fighters/">
              Site
            </Button>
          </CardActions>
        </Card>
        <Card className={classes.root}>
          <CardActionArea>
          <CardMedia
            component="img"
            alt="Jeopardy Lite Splash Page"
            height="140"
            image="https://i.imgur.com/wcCEpC0.png"
            title="Jeopardy Lite"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Jeopardy! Lite
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Jeopardy Lite! is a fullstack React application used to simulate a Jeopardy experience.
              Developed during a weeklong sprint.
            </Typography>
          </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://github.com/triple-jeopardy">
              GitHub
            </Button>
            <Button size="small" color="primary" href="https://jeopardy-lite.netlify.app/">
              Site
            </Button>
          </CardActions>
        </Card>
      </div>
    </section>
  )
}