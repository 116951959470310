import React from 'react'
import './AboutPage.css'
import { 
  DiReact as ReactIcon, 
  DiCss3 as CSSIcon,
  DiHtml5 as HTMLIcon,
  DiJsBadge as JSIcon,
  DiPostgresql as PostGresIcon,
  DiGithubBadge as GitIcon,
  DiMongodb as MongoIcon,
  DiNodejsSmall as NodeIcon

 } from "react-icons/di";

export default function AboutPage() {
  return (
      <section className="about_container">
        <div className="about_header_container">
           <h1 className="about_header">ABOUT</h1>
        </div>
        <img className="selfie" src='https://i.imgur.com/dOsKPUJ.jpg' alt="Joey Leaptrott" />
        <div className="about_paragraph_container">
        <p className="about_paragraph">I am a Freelance Fullstack Software Engineer. Live and have lived in the PDX my whole life. 
         Passionate new technologies and accessible design. Open to new opportunities.</p>
       </div>
        <div className="icon_container">
          <div className="icon_grid">
            <ReactIcon className="icons"/>
            <JSIcon className="icons"/>
            <CSSIcon className="icons"/>          
            <HTMLIcon className="icons"/>         
            <NodeIcon className="icons"/>
            <PostGresIcon className="icons"/>
            <MongoIcon className="icons"/>
            <GitIcon className="icons"/>
          </div>
       </div>
      </section>
  )
}
