import React from 'react'
import { Button } from '@material-ui/core'
import './NavBar.css'

export default function NavBar() {
  const style = {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    color: 'white',
    height: '50%',
    // padding: '0 30px',
    // boxShadow: '0 3px 5px 2px rgba(255, 255, 255, .3)',
    marginLeft: 2,
    marginRight: 2,
    fontFamily: 'Roboto',
    fontSize: 12
  };

  return (
    <div className="navbar">
      <Button style={style}>Projects</Button>
      <Button style={style}>About</Button>
      <Button style={style}>Resume</Button>
      <Button style={style}>Blog</Button>
      <Button style={style}>Contact</Button>
    </div>
  )
}
