import React from 'react';
import NavBar from '../NavBar/NavBar.jsx';
import HomePage from '../HomePage/HomePage.jsx'
import ContactPage from '../ContactPage/ContactPage.jsx'
import ProjectPage from '../ProjectPage/ProjectPage.jsx'
import BlogPage from '../BlogPage/BlogPage.jsx'
// import Footer from '../Footer/Footer.jsx'
// import ResumePage from '../ResumePage/ResumePage.jsx'
import AboutPage from '../AboutPage/AboutPage.jsx'
import './reset.css'

export default function App() {
  return (
    <>
    <HomePage />
    <NavBar />
    <AboutPage />
    <ProjectPage />
    <BlogPage />
    <ContactPage />
    </>
  );
}
  
